import { Socket } from 'socket.io-client';
import { useSocketIoFactory } from './useSocketIoFactory';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  addNotificationToasty,
  setNotificationsSocketConnection,
} from '@/lib/store/notifications/actions';
import { Notification } from '@/models/notification/notification';
import { AnyAction, Dispatch } from 'redux';
import { NotificationTypeId } from '@/enums/notificationTypeId';
import { NotificationGroupsQueryKey } from '@/api/notification-groups/queryKey';
import { UseNotificationGroupsMeQueryKey } from '@/api/notification-groups/queries/useNotificationGroupsMe/props';
import { PaginatedResponse } from '@/models/pagination/paginated-response.dto';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { NotificationGroup } from '@/models/notification/notificationGroup';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { store } from '@/lib/store';
import { UserBonusQueryKeys } from '@/api/user-bonus/queryKeys';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import Router from 'next/router';
import { ModalsKey } from '@/enums/modalsKey';
import { DepositStep } from '@/enums/depositStep';
import { TransactionExtraParams } from '@/models/notification/notificationExtraParams';
import { WithdrawStep } from '@/enums/withdrawStep';
import { setHasSlotSession } from '@/lib/store/casino/actions';
import { DepositQueryKeys } from '@starsoft/common/api/deposit';
import { WithdrawalsQueryKeys } from '@starsoft/common/api/withdrawals';
import { UserTagManagerEventsQueryKeys } from '@/api/user-tag-manager-events/queryKeys';
import { AuthService } from '@starsoft/common/services';

export default function useNotificationsSocket() {
  const socket: Socket | null = useSocketIoFactory({
    gateway: 'notifications',
    isProtected: true,
  });

  const dispatch: Dispatch<AnyAction> = useDispatch();
  const queryClient: QueryClient = useQueryClient();
  const onConnect: () => void = useCallback(() => {
    socket?.emit('join');
    dispatch(setNotificationsSocketConnection(true));
    if (AuthService.isAuthorized()) {
      queryClient.invalidateQueries({
        queryKey: [UserTagManagerEventsQueryKeys.Primary],
      });
    }
  }, [socket, dispatch, queryClient]);

  const onDisconnect: () => void = useCallback(() => {
    dispatch(setNotificationsSocketConnection(false));
  }, [dispatch]);

  function notificationGroupsMeUpdater(
    notificationsGroups:
      | PaginatedResponse<NotificationGroupWithUnreadCount>
      | undefined,
    updatedNotificationGroup: NotificationGroup,
  ) {
    if (!notificationsGroups) {
      return notificationsGroups;
    }

    const updatedNotificationGroupsData: NotificationGroupWithUnreadCount[] =
      notificationsGroups.data.map(notificationGroup => {
        if (notificationGroup.id !== updatedNotificationGroup?.id) {
          return notificationGroup;
        }

        const updatedUnreadCount: number = notificationGroup.unreadCount + 1;

        return {
          ...notificationGroup,
          unreadCount: updatedUnreadCount,
        };
      });

    return {
      ...notificationsGroups,
      data: updatedNotificationGroupsData,
    };
  }

  function handleDepositNotification(notification: Notification) {
    queryClient.invalidateQueries({
      queryKey: [DepositQueryKeys.ById],
    });
    queryClient.invalidateQueries({
      queryKey: [UserBonusQueryKeys.Primary],
    });
    queryClient.invalidateQueries({
      queryKey: [UserTagManagerEventsQueryKeys.Primary],
    });

    if (store.getState().casino.hasSlotSession) {
      store.dispatch(setHasSlotSession(false));
    }

    if (
      Router.asPath.includes(`#${ModalsKey.Deposit}`) &&
      !Router.asPath.includes(DepositStep.Response)
    ) {
      const extraParams = notification?.extra as TransactionExtraParams;
      Router.push({
        query: `step=${DepositStep.Response}&deposit-id=${extraParams?.id}`,
        hash: ModalsKey.Deposit,
      });
    }
  }

  function handleWithdrawalNotification(notification: Notification) {
    queryClient.invalidateQueries({
      queryKey: [WithdrawalsQueryKeys.ById],
    });

    queryClient.invalidateQueries({
      queryKey: [UserTagManagerEventsQueryKeys.Primary],
    });

    if (store.getState().casino.hasSlotSession) {
      store.dispatch(setHasSlotSession(false));
    }

    if (
      Router.asPath.includes(`#${ModalsKey.Withdraw}`) &&
      !Router.asPath.includes(WithdrawStep.Response)
    ) {
      const extraParams: TransactionExtraParams =
        notification?.extra as TransactionExtraParams;
      Router.push({
        query: `step=${WithdrawStep.Response}&withdraw-id=${extraParams?.id}`,
        hash: ModalsKey.Withdraw,
      });
    }
  }

  const onNotificationReceived: (notification: Notification) => void =
    useCallback(
      (notification: Notification) => {
        store.dispatch(addNotificationToasty(notification));

        const notificationGroupsMeQueryKey: UseNotificationGroupsMeQueryKey = [
          NotificationGroupsQueryKey.Me,
          {
            page: 1,
            limit: 20,
            groupIds: [
              NotificationGroupId.Inbox,
              NotificationGroupId.Comissions,
            ],
          },
        ];

        queryClient.setQueriesData<
          PaginatedResponse<NotificationGroupWithUnreadCount>
        >(
          {
            queryKey: notificationGroupsMeQueryKey,
          },
          (
            notificationsGroup:
              | PaginatedResponse<NotificationGroupWithUnreadCount>
              | undefined,
          ) =>
            notificationGroupsMeUpdater(
              notificationsGroup,
              notification?.type?.group,
            ),
        );

        switch (notification.type.id) {
          case NotificationTypeId.Deposit:
            handleDepositNotification(notification);
            break;
          case NotificationTypeId.Withdrawal:
            handleWithdrawalNotification(notification);
            break;
        }

        queryClient.invalidateQueries({
          queryKey: notificationGroupsMeQueryKey,
        });
      },
      //eslint-disable-next-line
      [],
    );

  function setServerOnEvents() {
    if (!socket) return;

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('notificationReceive', onNotificationReceived);
  }

  useEffect(setServerOnEvents, [
    socket,
    onConnect,
    onDisconnect,
    onNotificationReceived,
  ]);
}
